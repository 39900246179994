import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from '~/locales/en/common';
import zh from '~/locales/zh/common';
import fr from '~/locales/fr/common';
import jp from '~/locales/jp/common';
import es from '~/locales/es/common';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      zh: {
        translation: zh,
      },
      fr: {
        translation: fr,
      },
      jp: {
        translation: jp,
      },
      es: {
        translation: es,
      },
    },
    fallbackLng: 'en',
    useSuspense: false,
    caches: ['localStorage'],
    detection: {
      order: ['path', 'localStorage'],
      excludeCacheFor: ['ppe'],
    },
  });

export default i18n;
