import 'core-js/stable';

import React from 'react';
import Head from 'next/head';
import { getName } from 'country-list';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/node';

import '~/i18n';
import { ProductContext, VerifiedContext, LogScaleContext, LocationContext } from '~/contexts';

import _ from 'lodash';
import queryString from 'query-string';
import 'antd/dist/antd.css';
import '@/styles/global.css';

Sentry.init({
  dsn: 'https://e2ed9fbd4ec34a9eb777a0b2d948c27c@sentry.io/4883335',
  enabled: true,
});

function MyApp({ Component, pageProps, err }) {
  const { t } = useTranslation();

  const [product, setProduct] = React.useState(
    process.browser ? localStorage.getItem('product') || 'n95Regular' : 'n95Regular'
  );

  const [verified, setVerified] = React.useState(false);
  const [scale, setScale] = React.useState('');

  const [country, setCountry] = React.useState();
  const [state, setState] = React.useState();
  const [county, setCounty] = React.useState();

  React.useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') return;

      const freshTime = localStorage.getItem('fresh_time');
      if (freshTime) {
        const timeDiff = dayjs(Date.now()).diff(dayjs(Number(freshTime)), 'h');
        if (timeDiff) {
          window.location.reload();
        }
      }
    });
  }, []);

  React.useEffect(() => {
    const qs = queryString.parse(window.location.search);
    if (_.has(qs, 'verified')) {
      setVerified(true);
    }

    if (localStorage) {
      setScale(localStorage.getItem('logScale') || 'value');
    }
  }, []);

  const modifiedPageProps = { ...pageProps, err };
  return (
    <ProductContext.Provider value={{ product, setProduct }}>
      <VerifiedContext.Provider value={{ verified, setVerified }}>
        <LogScaleContext.Provider value={{ scale, setScale }}>
          <LocationContext.Provider value={{ county, setCounty, state, setState, country, setCountry }}>
            <Head>
              <title>{t('app.title')}</title>
            </Head>
            <Component {...modifiedPageProps} />
          </LocationContext.Provider>
        </LogScaleContext.Provider>
      </VerifiedContext.Provider>
    </ProductContext.Provider>
  );
}

export default MyApp;
